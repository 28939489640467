<template>
    <div>
        <b-overlay :show="loadDimension" rounded="sm">

            <div class="container_site">

                <div class="container_dimension_mobile mb-3" :style="{'border-top': '10px solid '+dimension.color_dimension}">
                    <div class="container_dimension_mobile--title_dimension" v-if="!openMenuDimension">
                        <div style="width: 98%">{{dimension.name_dimension}}</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <MenuIcon />
                        </a>
                    </div>

                    <div class="container_dimension_mobile--title_dimension" v-if="openMenuDimension">
                        <div style="width: 100%" v-if="dimension.subcategory">Selecciona una secretaría</div>
                        <div style="width: 100%" v-else>Selecciona una dimensión</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <ArrowUpIcon width="18.828" height="10.414" color="#000000" />
                        </a>
                    </div>

                    <DimensionMovileList v-if="openMenuDimension" :subcategory="dimension.subcategory"/>
                    
                </div>

                <div class="container_back_mobile">
                  <a @click="$router.go(-2)">
                      <img src="/img/icono-volver.bc8ecbba.svg" class="container_back_mobile__icon_back" > 
                      <p class="container_back_mobile__title"><span class="container_back_mobile__title--back">Volver a</span> {{Segment.name}}</p> 
                  </a>
                </div>

                <div class="container_back_category_mobile">
                    <img src="@/assets/img/linea-segmento.svg" class="container_back_category_mobile__icon_segment_line" > 
                  <a @click="$router.go(-1)">
                      <img src="/img/icono-volver.bc8ecbba.svg" class="container_back_category_mobile__icon_back" > 
                      <p class="container_back_mobile__title"><span class="container_back_category_mobile__title--back ">Volver a</span> {{Category.name}}</p> 
                  </a>
                </div>

                <div class="container_dimension_mobile__card_segments--mobile">
                    <div class="container_dimension_mobile__card_segment--title_segment">
                        {{Product.name}}
                    </div>
                    <div class="container_dimension_mobile__card_segment--description_segment">
                        {{Product.description}}
                    </div>


                            <div class="container_products--container__metric">
                                <div v-for="info in Product.product_tables" :key="info" class="container_metric  graph_card">
                                <Graphs
                                    :type="{
                                    data: info.data,
                                    description: info.description,
                                    name: info.name,
                                    type: info.graph_type_id,
                                    }"
                                />
                                <div v-if="info.description">
                                    </br></br></br></br></br></br></br>
                                </div>
                                    <p v-if="info.description" v-html="info.description" class="container_metric--description"></p>
                                </div>
                            </div>

                   


                </div>



                <div class="container_btn_actions mb-5">
                    <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
                        <img src="@/assets/img/icono-regresar.svg" alt="">
                        <p>Regresar</p>
                    </div>
                    <div class="container_btn_actions__btn_home" @click="$router.push({name: 'Home'})">
                        <img src="@/assets/img/icono-inicio.svg" alt="">
                        <p>Inicio</p>
                    </div>
                </div>



            </div>
        </b-overlay>
    </div>
</template>


<script>
export default {
    data() {
        return {
            id:this.$route.params.id_dimension,
            Segment_name:this.$route.params.Segment_name,
            dimension:[],
            loadDimension: true,
            openMenuDimension: false,
            Segment: [],
            Category: [],
            Product: []
        }
    },
    methods:{
        
      
        getDimension(){
            this.$http.get('dimensions/'+this.id).then(response =>{
                this.getSegments();
                this.dimension = response.body;
                console.Log('dimension', this.dimension)
            }).catch(error => {
                // console.Log('Error al consultar la dimensión: ', error)
            })
        },

        getSegments(){
            this.$http.get('architecture_tags/dimension/'+this.id).then(response =>{
                this.Category = null;
                this.segments = response.body.Segments;
                this.loadDimension = false;
                response.body.Segments.forEach(Segment => {
                    
                    if (Segment.id == this.$route.query.Segment_id) {
                            
                            this.Segment = Segment
                        Segment.tags.categories.forEach(Category => {

                            if (Category.id == this.$route.query.Category_id) {
                                this.Category = Category;
         
                                    Category.products.forEach(Product => {
                                        if (Product.id == this.$route.query.Product) {
                                                this.Product = Product;
                                                console.log('product',this.Product)
                                        }
                                    });
                            }
                        });
                    }
                });


                console.log('category', this.Category)
                
            }).catch(error => {
                //Console.Log('Error al consultar los segmentos: ', error)
            })
        }

    },
    created() {
        this.getDimension();
        
    },
    
    computed: {
        cssStyle(){
            return {
                '--color-dimension' : this.dimension.color_dimension
            }
        }
    },
}
</script>

<style scoped>

.graph_card{
    margin-top: 15px;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    min-height: 440px;
    width: auto;
    opacity: 1; 
    margin-bottom: 20px;

}


</style>